import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import ReactPlayer from 'react-player';

function Home() {
  const { t } = useTranslation();

  const title = 'Big Boss Generation';

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-fluid fade-in">
        <div className="px-4 py-0 text-center home-content">
          <div className="col-12 mx-auto pt-5 pt-md-4">
            <div className='row fs-1 bg-tr-dark p-2 mt-5' style={{ height: window.innerWidth >= 640 ? '60vh' : ((window.innerWidth / 16) * 8) + 'px' }}>
              <ReactPlayer
                url='/images/BigBossVideo3_Short.mp4'
                controls={true}
                playing={true} // Enables autoplay
                muted={true} // Required for autoplay to work in most browsers
                width="auto"
                height="100%"
                style={{ maxHeight: '100%', width: 'auto' }}
                className='mx-auto'
              />
            </div>
            <div className='row fs-1' style={{ height: '60vh' }}>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-md-5 glow-link">
                <div className='align-self-md-center align-self-end mb-md-5 home-link text-chaman'
                  onClick={() => navigate('/about')}
                ><Trans i18nKey='home-about' /></div>
              </div>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-md-5 glow-link">
                <div className='align-self-center mb-md-5 home-link text-chaman'
                  onClick={() => navigate('/buy')}
                ><Trans i18nKey='home-apply' /></div>
              </div>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-5 glow-link">
                <div className='align-self-md-center align-self-sm-start mb-5 home-link text-chaman'
                  onClick={() => navigate('/contact')}
                >{t('home-contact')}</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Home;
